<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { vaultService } from '../../../services/vault.service';

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,
  numeric,
} from "vuelidate/lib/validators";

import { accountsService } from '../../../services/accounts.service';


const initForm = { amount: "",currencyType: "", sourceAccountId:"",  transactionType : "inter-branch-transfer", supervisorId: JSON.parse(localStorage.getItem('user')).id, destinationAccountId: "", narration:""};

export default {
page: {
    title: "Account",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
},
    data() {

        return {
            accountList: [],
            fundingSuccess:false,
            fundingFailed: false,
            responseMessage:"",
            loading:false,

            currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"rand",
                    currencyName: "RAND"
                },
                {
                    currencyType:"pound",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"euro",
                    currencyName: "EURO"
                }
            ],
            title: "Vault",
            items: [
                {
                    text: "Account ",
                    href: "/funds-transfer"
                },
                {
                    text: "Transfers",
                    active: true
                }
            ],

            form: {
                supervisorId: JSON.parse(localStorage.getItem("user")).id,
                destinationAccountId: "",
                currencyType: null,
                amount: null,
                sourceAccountId: "",
                transactionType : "inter-branch-transfer",
                narration:""
            },

            submitted: false,
            submitform: false,
            submit: false
        };
    },

    validations: {
        form: {
            destinationAccountId: {required},
            amount: { required, numeric },
            currencyType: { required },
            sourceAccountId: {required},
            narration: { required }
        },
    },

    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    created() {
        this.loadAllAccounts();
    },

    methods: { 
        ...notificationMethods,

    async loadAllAccounts() {
        try {
            await accountsService.getAllAccounts().then(response=>{
                if(response.responseBody.length>0){
                    this.accountList = response.responseBody;
                }
            });
        } catch (error) {
            console.log(error);
        }
    },

    formSubmit() {
       
            this.submitted = true;
            this.loading = true;
            this.$v.$touch();
           
            
            if (this.$v.$invalid) { 
         
                this.loading = false;
                return;
            } else {
                const {form} = this;
                const vaultForm = {
                        "operation" : "supervisor activity",
                        "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                        "requestBody" : form
                };
                
                vaultService.supervisorActivity(vaultForm).then(result=>{
                    if(result.status=="SUCCESS"){
                        this.fundingSuccess = true;
                        this.submitted = false;
                        this.loading = false;
                        this.form = Object.assign({}, initForm);
                        this.responseMessage = result.message;
                    } else {
                        this.submitted = false;
                        this.loading = false;
                        this.responseMessage = result.message;
                        this.fundingFailed = true;
                    }
                });
            }
        },
    },

};

</script>

<template>
<Layout>
<PageHeader :title="title" :items="items" />
 <div class="card col-md-8" >
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h5>Account to Account Transfer</h5>
                </div>
            </div>
        </div>

        <div class="no-gutters">
            <p>Transfer funds between accounts</p>
            <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="fundingFailed">
                <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
            </h5>
            <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="fundingSuccess">
                <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
            </h5>
            <form class="needs-registration" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label>Select source account</label>
                        <select class="form-control" v-model="form.sourceAccountId" :class="{ 'is-invalid': submitted && $v.form.sourceAccountId.$error }">
                            <option v-for="(account, index) in accountList" :value="account.id" :key="index">{{account.accountName}}</option>
                        </select>
                        <div v-if="submitted && $v.form.sourceAccountId.$error" class="invalid-feedback">
                            <span v-if="!$v.form.sourceAccountId.required">This value is required.</span>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Select destination account</label>

                            <select class="form-control" v-model="form.destinationAccountId" :class="{ 'is-invalid': submitted && $v.form.destinationAccountId.$error }">
                                <option v-for="(dest, index) in accountList" :value="dest.id" :key="index">{{dest.accountName}}</option>
                            </select>
                            <div v-if="submitted && $v.form.destinationAccountId.$error" class="invalid-feedback">
                                <span v-if="!$v.form.destinationAccountId.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="validationCustom02">Amount</label>
                                    <div>
                                        <input
                                            id="validationCustom02"
                                            v-model="form.amount"
                                            type="number"
                                            class="form-control"
                                            placeholder="Enter Amount "
                                            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
                                        />
                                        <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.amount.required">This value is required.</span>
                                            <span v-if="!$v.form.amount.numeric">This value should be a valid number.</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Currency</label>
                                <select class="form-control" v-model="form.currencyType" :class="{ 'is-invalid': submitted && $v.form.currencyType.$error }">
                                    <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                </select>
                                <div v-if="submitted && $v.form.currencyType.$error" class="invalid-feedback">
                                    <span v-if="!$v.form.currencyType.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Narration</label>
                                    <div>
                                        <input
                                            v-model="form.narration"
                                            type="text"
                                            class="form-control"
                                            placeholder="Purpose"
                                            :class="{ 'is-invalid': submitted && $v.form.narration.$error }"
                                        />
                                        <div v-if="submitted && $v.form.narration.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.narration.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5" v-if="!loading">
                            <button class="btn btn-primary" type="submit">Transfer Funds</button>
                        </div>
                        <div class="col-md-6">
                            <div class="spinner" v-if="loading">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
                        </div>
                    </div>
                
            </form>
        </div>
    </div>
    </div>
    </Layout>   
</template>